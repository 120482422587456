import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationComponent } from './notification.component';
import { NotificationService } from './notification.service';
import { NotificationState } from './notification.state';
import { RemovablePipe } from './pipes/removable.pipe';
import { SharedModule } from '@shared/shared.module';

@NgModule({
  declarations: [NotificationComponent, RemovablePipe],
  exports: [NotificationComponent],
  imports: [CommonModule, SharedModule],
  providers: [NotificationService, NotificationState],
})
export class NotificationModule {}
