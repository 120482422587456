import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { UserService } from '@core/api/user.service';
import { controlsEqual } from '@core/validators/confirmation.validator';
import { TranslateService } from '@ngx-translate/core';
import { ValidatorService } from '@capturum/ui/api';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { AuthService } from '@capturum/auth';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
})
export class ResetPasswordComponent implements OnInit {
  public form: UntypedFormGroup;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private authService: AuthService,
    private validatorService: ValidatorService,
    private translateService: TranslateService,
    private dialogRef: DynamicDialogRef,
    private userService: UserService,
  ) {}

  public ngOnInit(): void {
    this.validatorService.setValidationMessages({
      equalsField: this.translateService.instant('user.detail.password-mismatch.message'),
    });

    this.form = this.formBuilder.group(
      {
        password: ['', Validators.required],
        password_confirmation: ['', Validators.required],
      },
      {
        validators: controlsEqual('password_confirmation', 'password', 'equalsField'),
      },
    );
  }

  public submit(): void {
    this.userService.update({ id: this.authService.getUser().id, ...this.form.value }).subscribe(() => {
      return this.dialogRef.close();
    });
  }
}
