import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { DxpIndexableBaseDataValueService } from '@core/indexDb/services/dxp-indexable-base-data-value.service';

@Injectable({ providedIn: 'root' })
export class DxpIndexableBaseDataValueResolver {
  constructor(private indexableService: DxpIndexableBaseDataValueService) {}

  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.indexableService.resolve();
  }
}
