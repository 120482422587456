import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { DxpIndexableTranslationService } from '@core/indexDb/services/dxp-indexable-translation.service';

@Injectable({ providedIn: 'root' })
export class DxpIndexablePrivateTranslationResolver {
  constructor(private indexableService: DxpIndexableTranslationService) {}

  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.indexableService.resolve();
  }
}
