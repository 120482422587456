import { Component, OnInit } from '@angular/core';
import { NotificationMessage, NotificationPosition } from './base/notification.model';
import { takeUntil, tap } from 'rxjs/operators';
import { DestroyBase } from '@core/base/destroy.class';
import { NotificationState } from '@shared/modules/notification/notification.state';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent extends DestroyBase implements OnInit {
  public visible: boolean;
  public position: NotificationPosition;
  public notifications: NotificationMessage[] = [];
  public stackedNumber = 4;
  public isStacked: boolean;

  constructor(private notificationState: NotificationState) {
    super();
  }

  public ngOnInit(): void {
    this.notificationState.closeById
      .asObservable()
      .pipe(
        tap((id: string) => {
          return this.close(id);
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();

    this.notificationState.notification$.pipe(takeUntil(this.destroy$)).subscribe((notification) => {
      if (this.allowDuplicate(notification)) {
        this.visible = !!notification;
        this.notifications = this.notifications ? [...this.notifications, notification] : [notification];
        this.position = notification.position;

        this.checkStackedStatus();
        this.closeAutomatically(notification);
      }
    });
  }

  public onClick(event: any, notification: NotificationMessage): void {
    notification.cta.callback(); // Default callback

    if (notification.cta.closeAfterCallback) {
      // Close after callback
      this.close(notification.id);
    }
  }

  public closeAutomatically(notification: NotificationMessage): void {
    setTimeout(
      () => {
        this.close(notification.id);
      },
      notification.timeout + this.notifications.length * 1000,
    );
  }

  public close(id: number | string, timeout = 500): void {
    const index = this.notifications.findIndex((item) => {
      return item.id === id;
    });

    if (index >= 0) {
      this.notifications[index].removed = true;
      this.notifications.splice(index, 1);

      setTimeout(() => {
        this.checkStackedStatus();
      }, timeout);
    }
  }

  public closeStacked(): void {
    this.notifications = this.notifications.map((item) => {
      item.removed = true;

      return item;
    });

    this.notifications = [];

    setTimeout(() => {
      this.checkStackedStatus();
    }, 500);
  }

  public checkStackedStatus(): void {
    if (this.notifications.length >= this.stackedNumber) {
      this.isStacked = true;
    }
  }

  public clearAll(): void {
    this.notifications = this.notifications.map((item) => {
      item.removed = true;

      return item;
    });

    this.notifications = [];
  }

  protected allowDuplicate(notification: NotificationMessage): boolean {
    if (notification.allowDuplicate === false) {
      const count = this.notifications ? this.notifications.length : 0;

      if (count > 0) {
        return !(
          this.notifications[count - 1].title === notification.title &&
          this.notifications[count - 1].message === notification.message &&
          this.notifications[count - 1].type === notification.type
        );
      }
    }

    this.closeStacked();

    return true;
  }
}
