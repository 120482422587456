import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Store } from '@ngxs/store';
import { ObservationSelectors } from '@store/mobile/observation/observation.selectors';
import { ResetObservation } from '@store/mobile/observation/observation.actions';
import { MobileUndoService } from '@core/services/mobile-undo.service';
import { Entity } from '@core/enums/entity.enum';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService } from 'primeng/api';
import { ResetStock } from '@store/mobile/stock/stock.actions';
import { ResetSorting } from '@store/mobile/sorting/sorting.actions';
import { ResetHarvestMove } from '@store/mobile/harvest-move/harvest-move.actions';

@Component({
  selector: 'app-undo-action',
  templateUrl: './undo-action.component.html',
  styleUrls: ['./undo-action.component.scss'],
})
export class UndoActionComponent implements OnInit, OnDestroy {
  public wasRemoved: boolean;
  public showUndo: boolean;
  public entity: Entity;
  public visible$: Observable<boolean>;
  public observationId$: Observable<string>;
  private destroy$: Subject<boolean> = new Subject();

  constructor(
    private store: Store,
    private mobUndoService: MobileUndoService,
    private translateService: TranslateService,
    private confirmationService: ConfirmationService,
  ) {
    this.visible$ = this.mobUndoService.getUndoVisible();
    this.observationId$ = this.store.select(ObservationSelectors.getObservationId);
  }

  public ngOnInit(): void {
    this.mobUndoService.undoAction$.pipe(takeUntil(this.destroy$)).subscribe((undoAction) => {
      this.showUndo = undoAction.visible;
      this.entity = undoAction.entity;
    });
  }

  public ngOnDestroy(): void {
    this.store.dispatch([new ResetObservation(), new ResetStock(), new ResetSorting(), new ResetHarvestMove()]);

    this.showUndo = false;
    this.mobUndoService.reset();
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  public confirm(): void {
    this.confirmationService.confirm({
      header: this.translateService.instant('dxp.mobile-app.undo.title', { actionName: this.getEntity() }),
      message: this.translateService.instant('dxp.mobile-app.undo.description', { actionName: this.getEntity() }),
      rejectLabel: 'button.cancel',
      acceptLabel: 'button.undo',
      accept: () => {
        this.remove();
      },
    });
  }

  public remove(): void {
    const removeMethod = `${this.entity}Removing`;

    this[removeMethod]();
  }

  public getEntity(): string {
    return this.translateService.instant(`dxp.${this.entity}.entity_name`);
  }

  public handleFinish(): void {
    this.showUndo = false;

    this.mobUndoService.setUndoFinished(true);
    this.wasRemoved = false;
  }
}
