import { Component } from '@angular/core';
import { ListRendererWidgetBase } from '@capturum/builders/list-renderer';

@Component({
  selector: 'app-lot-product',
  styleUrls: ['./lot-product.component.scss'],
  template: `
    <div class="product-name">{{ item?.grading?.product?.short_name }}</div>
    <div class="product-characteristics">{{ item?.characteristic_positioning }}</div>
  `,
})
export class LotProductComponent extends ListRendererWidgetBase {}
