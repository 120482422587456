import { Injectable } from '@angular/core';
import { ActionOptions, BackendAction, BuilderApiService } from '@capturum/builders/core';
import { DocumentService } from '@core/api/document.service';
import { NotificationService } from '@shared/modules/notification/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { DocumentBatch } from '@core/models/document.model';
import { ApiHttpService } from '@capturum/api';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class BackendService extends BackendAction {
  constructor(
    private documentService: DocumentService,
    private notificationService: NotificationService,
    private translateService: TranslateService,
    protected readonly builderApiHttpService: BuilderApiService,
    protected readonly apiHttp: ApiHttpService,
  ) {
    super(apiHttp, builderApiHttpService);
  }

  public execute(options: ActionOptions, item: any): Observable<any> {
    if (options?.action?.key === 'print_outbound_trolley_notes') {
      this.documentService
        .documentGeneration({
          documentable_id: item.id,
          documentable_type: 'batch',
          document_key: 'outbound_trolley_note',
          email: null,
          variables: {},
        })
        .subscribe((generationStatus: DocumentBatch) => {
          if (generationStatus?.message) {
            this.notificationService.warning(
              this.translateService.instant('dxp.toast.warning.title'),
              generationStatus.message,
            );
          } else {
            this.notificationService.success(
              this.translateService.instant('toast.success.title'),
              this.translateService.instant('dxp.document.send-to-printer'),
            );
          }
        });
    } else {
      return super.execute(options, item).pipe(
        tap((response) => {
          if (response?.data?.message) {
            this.notificationService.warning(
              this.translateService.instant('dxp.toast.warning.title'),
              response.data.message,
            );
          }
        }),
      );
    }
  }
}
