import { ObservationState } from './mobile/observation/observation.state';
import { StockState } from './mobile/stock/stock.state';
import { SortingState } from './mobile/sorting/sorting.state';
import { SyncState } from './mobile/sync/sync.state';
import { HarvestMoveState } from './mobile/harvest-move/harvest-move.state';
import { RejectedStockState } from './mobile/rejected-stock/rejected-stock.state';
import { StartShipmentState } from './mobile/start-shipment/start-shipment.state';
import { ConfirmShipmentState } from './mobile/confirm-shipment/confirm-shipment-state.service';
import { RecordEmptyState } from './mobile/record-empty/record-empty.state';
import { DeliveryNoteState } from '@store/mobile/delivery-note/delivery-note.state';
import { GradedStockState } from '@store/admin/graded-stock/graded-stock.state';
import { GradingState } from '@store/admin/grading/grading.state';
import { GeneralState } from '@store/admin/general/general.state';
import { TemperatureRecordingsState } from './public/temperature-recordings/temperature-recordings.state';
import { RejectedShipmentsState } from '@store/mobile/rejected-shipments/rejected-shipments.state';
import { ReceiveShipmentState } from '@store/public/receive-shipment/receive-shipment.state';
import { ConfirmLotState } from '@store/mobile/confirm-lot/confirm-lot-state.service';

export const MobileState = [
  ObservationState,
  StockState,
  SortingState,
  SyncState,
  HarvestMoveState,
  RejectedStockState,
  StartShipmentState,
  ConfirmShipmentState,
  RecordEmptyState,
  DeliveryNoteState,
  RejectedShipmentsState,
  ConfirmLotState,
];

export const AdminState = [GradingState, GeneralState, GradedStockState];

export const PublicState = [ReceiveShipmentState, TemperatureRecordingsState];
