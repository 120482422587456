import { GeneralState } from './store/admin/general/general.state';
import { BrowserModule } from '@angular/platform-browser';
import { Injector, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from '@core/core.module';
import { MissingTranslationHandler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { CustomMissingTranslationHandler } from '@core/translate/custom-missing-translation-handler';
import { HttpClientModule } from '@angular/common/http';
import { TranslationLoader } from '@capturum/complete';
import { NgxPermissionsModule } from 'ngx-permissions';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppInjector } from './app-injector.service';
import { NgxsModule } from '@ngxs/store';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NotificationModule } from '@shared/modules/notification/notification.module';
import { AdminState, MobileState, PublicState } from './store';
import { ngxsConfig } from '../ngxs.config';
import { LayoutsModule } from '@shared/modules/layouts/layouts.module';
import { UndoActionComponent } from './features/mobile/mobile-dashboard/components/undo-action/undo-action.component';
import { SharedModule } from '@shared/shared.module';
import { CountdownComponent } from './features/mobile/mobile-dashboard/components/countdown/countdown.component';
import { APP_BASE_HREF } from '@angular/common';
import { ResetPasswordComponent } from './features/admin/user/pages/reset-password/reset-password.component';
import { ReactiveFormsModule } from '@angular/forms';
import { StartGradingPopupComponent } from './features/admin/grading/components/start-grading-popup/start-grading-popup.component';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService } from 'primeng/api';
import { CapturumPowerBIModule } from '@capturum/powerbi';

@NgModule({
  declarations: [
    AppComponent,
    UndoActionComponent,
    CountdownComponent,
    ResetPasswordComponent,
    StartGradingPopupComponent,
  ],
  imports: [
    ReactiveFormsModule,
    BrowserModule,
    SharedModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    CoreModule,
    HttpClientModule,
    CapturumPowerBIModule,
    NotificationModule,
    TranslateModule.forRoot({
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: CustomMissingTranslationHandler,
      },
      loader: {
        provide: TranslateLoader,
        useClass: TranslationLoader,
      },
    }),
    NgxPermissionsModule.forRoot(),
    NgxsModule.forRoot([...MobileState, ...AdminState, ...PublicState, GeneralState], ngxsConfig),
    NgxsStoragePluginModule.forRoot(),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    LayoutsModule,
    ConfirmDialogModule,
  ],
  providers: [{ provide: APP_BASE_HREF, useValue: '/' }, ConfirmationService],
  exports: [],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(injector: Injector) {
    AppInjector.setInjector(injector);
  }
}
