import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { DxpIndexablePermissionService } from '@core/indexDb/services/dxp-indexable-permission.service';

@Injectable({ providedIn: 'root' })
export class ClearPermissionsGuard {
  constructor(private dxpIndexablePermissionService: DxpIndexablePermissionService) {}

  public canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.dxpIndexablePermissionService.resetPermissions();
  }
}
