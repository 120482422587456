export class LocalStorageUtil {
  public static clearTableStates(): void {
    const excludedKeys = [
      'token',
      'user',
      'lastRoute',
      'currentLocaleCode',
      'last_data_fetched_at',
      'updated_key_data',
    ];

    for (const key in localStorage) {
      if (localStorage.hasOwnProperty(key)) {
        const isExcluded = excludedKeys.includes(key);

        if (!isExcluded) {
          localStorage.removeItem(key);
        }
      }
    }
  }
}
