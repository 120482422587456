import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { DxpIndexableSettingService } from '@core/indexDb/services/dxp-indexable-setting.service';

@Injectable({ providedIn: 'root' })
export class DxpIndexableSettingResolver {
  constructor(private indexableService: DxpIndexableSettingService) {}

  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.indexableService.resolve();
  }
}
