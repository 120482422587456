import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TranslationKeyApiModel, TranslationKeyService } from '@capturum/complete';
import { ApiIndexResult, ListOptions } from '@capturum/api';

@Injectable({
  providedIn: 'root',
})
export class MarginparTranslationKeyService extends TranslationKeyService {
  public get<T = TranslationKeyApiModel>(id: number | string, options?: ListOptions): Observable<T> {
    let listOptions = options;

    if (listOptions) {
      listOptions = {
        ...listOptions,
        include: listOptions.include.filter((include) => {
          return include !== 'translations';
        }),
      };
    }

    return super.get(id, listOptions);
  }

  public index<T = TranslationKeyApiModel>(options?: ListOptions): Observable<ApiIndexResult<T>> {
    let listOptions = options;

    if (listOptions) {
      listOptions = {
        ...listOptions,
        include: listOptions.include.filter((include) => {
          return include !== 'translations';
        }),
      };
    }

    return super.index(listOptions);
  }
}
