import { Component, Input, ViewEncapsulation } from '@angular/core';
import { ListRendererWidgetBase } from '@capturum/builders/list-renderer';

@Component({
  selector: 'app-list-renderer-widget-image',
  template: `
    @if (item) {
      <div class="list-renderer-widget-image list-renderer-widget-image--{{ shape }}">
        <img [src]="item[field]" class="thumbnail" />
        <img class="enlarged-thumbnail" [src]="item[field]" />
      </div>
    }
  `,
  styleUrls: ['./list-renderer-widget-image.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ListRendererWidgetImageComponent extends ListRendererWidgetBase {
  @Input() public shape: 'square' | 'circle' = 'square';
}
