import { MissingTranslationHandler, MissingTranslationHandlerParams } from '@ngx-translate/core';
import { environment } from '@environments/environment';

export class CustomMissingTranslationHandler implements MissingTranslationHandler {
  public handle(params: MissingTranslationHandlerParams): string {
    // Parse missing translations for development purposes
    const parts = params.key.split('.');

    if (parts.length === 1 || params.key.indexOf(' ') !== -1) {
      return params.key;
    }

    if (environment.parseMissingTranslations) {
      // e.g. 'production.farm_name.label' will become 'Farm name'
      if (parts.length === 2 || parts.length === 3) {
        const label = parts[1].replace('_', ' ').replace('-', ' ');

        return label.charAt(0).toUpperCase() + label?.slice(1);
      }
    }

    return `[[ ${params.key} ]]`;
  }
}
