import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { DxpIndexablePermissionService } from '@core/indexDb/services/dxp-indexable-permission.service';
import { map } from 'rxjs/operators';
import { AppRoutes } from '@core/enums/routes.enum';

@Injectable({ providedIn: 'root' })
export class CheckPermissionsGuard {
  constructor(
    private dxpIndexablePermissionService: DxpIndexablePermissionService,
    private readonly router: Router,
  ) {}

  public canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> {
    return this.dxpIndexablePermissionService.resolve().pipe(
      map((isResolved: boolean) => {
        return isResolved ? isResolved : this.router.createUrlTree(['/', AppRoutes.auth, AppRoutes.login]);
      }),
    );
  }
}
