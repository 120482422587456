<div
  class="notification"
  [ngClass]="{ 'notification--show': visible }"
  [ngStyle]="{
    top: position?.top,
    right: position?.right,
    bottom: position?.bottom,
    left: position?.left,
  }">
  @if (notifications?.length <= stackedNumber || !isStacked) {
    @for (notification of notifications; track notification) {
      <div
        class="notification__message {{ notification?.type }}"
        data-test="notification"
        [ngClass]="{ removed: notification?.removed, timed: notification?.timeout }">
        <p class="message__title" [innerHTML]="notification?.title"></p>
        <p class="message__body" [innerHTML]="notification?.message"></p>
        @if (notification?.cta; as callToAction) {
          <div class="message__cta">
            <cap-button
              styleClass="primary"
              [icon]="callToAction?.icon"
              [label]="callToAction?.label | observablePipe | async"
              (onClick)="onClick($event, notification)">
            </cap-button>
          </div>
        }
        <i class="fal fa-times message--close" (click)="close(notification?.id)"></i>
        @if (notification?.showTimeoutBar) {
          <div class="message__progressbar" [style.animation-duration]="notification?.timeout + 'ms'"></div>
        }
      </div>
    }
  } @else {
    <div class="notification__message stacked" [ngClass]="{ removed: notifications | removable }">
      <p class="message__title">{{ 'dxp.toast.stacked.title' | translate: { length: notifications?.length } }}</p>
      <p class="message__stacked-action" (click)="isStacked = false">{{ 'dxp.toast.stacked.cta' | translate }}</p>
      <i class="fal fa-times message--close" (click)="closeStacked()"></i>
    </div>
  }

  <ng-template #stacked>
    <div class="notification__message stacked" [ngClass]="{ removed: notifications | removable }">
      <p class="message__title">{{ 'dxp.toast.stacked.title' | translate: { length: notifications?.length } }}</p>
      <p class="message__stacked-action" (click)="isStacked = false">{{ 'dxp.toast.stacked.cta' | translate }}</p>
      <i class="fal fa-times message--close" (click)="closeStacked()"></i>
    </div>
  </ng-template>
</div>
