import { Injectable } from '@angular/core';
import { ActionOptions, ConfirmationBackendAction, BuilderApiService } from '@capturum/builders/core';
import { ConfirmationService } from 'primeng/api';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Store } from '@ngxs/store';
import { FetchActionsExecution } from '@store/general/general.actions';
import { BuilderActionType } from '@core/enums/builder-action-type.enum';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from '@shared/modules/notification/notification.service';
import { EntityAction } from '@core/enums/entity-action.enum';

@Injectable({
  providedIn: 'root',
})
export class ListBackendConfirmationService extends ConfirmationBackendAction {
  constructor(
    protected readonly builderApiService: BuilderApiService,
    protected readonly confirmationService: ConfirmationService,
    protected readonly translateService: TranslateService,
    private readonly store: Store,
    private readonly notificationService: NotificationService,
  ) {
    super(builderApiService, confirmationService, translateService);
  }

  public execute(options: ActionOptions, item: any): Observable<any> {
    return super.execute(options, item).pipe(
      tap((response) => {
        if (response) {
          this.notificationService.entityActionSuccess('Item', EntityAction.unplan, true);
          this.store.dispatch(new FetchActionsExecution(BuilderActionType.backendConfirmation));
        }
      }),
    );
  }
}
