import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from '@shared/modules/notification/notification.service';
import { AppRoutes } from '@core/enums/routes.enum';
import { PreviousRouteService } from '@core/services/previous-route.service';
import User from '@core/models/user.model';
import { TranslateService } from '@ngx-translate/core';
import { NavigationService } from '@core/services/navigation.service';
import { DxpIndexablePermissionService } from '@core/indexDb/services/dxp-indexable-permission.service';
import { first } from 'rxjs/operators';
import { DateHelper } from '@core/utils/date.helper';
import { AuthService } from '@capturum/auth';
import { LoadingService } from '@capturum/ui/loader';
import { LoginConfig, LoginService as CapturumLoginService } from '@capturum/login';
import { ToastService } from '@capturum/ui/api';
import { UntypedFormBuilder } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class LoginService extends CapturumLoginService {
  constructor(
    protected readonly config: LoginConfig,
    protected readonly router: Router,
    protected readonly activatedRoute: ActivatedRoute,
    protected readonly toastService: ToastService,
    protected readonly translateService: TranslateService,
    protected readonly notificationService: NotificationService,
    protected readonly formBuilder: UntypedFormBuilder,
    private readonly previousRouteService: PreviousRouteService,
    private readonly loadingService: LoadingService,
    private readonly navigationService: NavigationService,
    private readonly dxpIndexablePermissionService: DxpIndexablePermissionService,
    private readonly authService: AuthService,
  ) {
    super(config, router, activatedRoute, toastService, translateService, formBuilder);
  }

  public onLoginSuccess(result: any): void {
    this.loadingService.toggleLoading(true, ' ', './assets/images/logo-b.png', 'dxp-resolver-loader');
    this.getAllShipmentCountries();

    if (result && result.updated_key_data) {
      localStorage.setItem('updated_key_data', JSON.stringify(result.updated_key_data));
    }

    /**
     * Get permissions. No need to check for already indexed permissions because they are cleared on auth/login guard.
     * Also, no need for validation, because
     */

    this.dxpIndexablePermissionService
      .resolve()
      .pipe(first())
      .subscribe(() => {
        const user = this.authService.getUser() as User;
        const lastRoute = this.previousRouteService.getLastAccessedRoute();
        const homepageRoute =
          (user && user.homepage && this.navigationService.getPageRouteByKey(user.homepage.value)) ||
          `/${AppRoutes.admin}/${AppRoutes.user}/${AppRoutes.profile}`;
        const date = DateHelper.toFormattedString(user?.previous_login_at);

        if (date) {
          this.notificationService.info(
            this.translateService.instant('link.login'),
            this.translateService.instant('auth.sign-in.previous', { date }),
            null,
          );
        }

        if (lastRoute) {
          this.router.navigateByUrl(lastRoute).then(() => {
            if (this.router.url === `/${AppRoutes.auth}/${AppRoutes.login}`) {
              const newRoute = homepageRoute
                ? homepageRoute
                : `/${AppRoutes.admin}/${AppRoutes.user}/${AppRoutes.profile}`;

              localStorage.setItem('lastRoute', newRoute);
              this.router.navigateByUrl(newRoute);
            }
          });
        } else if (homepageRoute) {
          this.router.navigateByUrl(homepageRoute);
        } else {
          super.onLoginSuccess(result);
        }
      });
  }

  public onLoginFail(result: any): void {
    switch (result.status) {
      case 422:
        this.notificationService.error(
          this.translateService.instant('link.login'),
          this.translateService.instant('auth.sign-in.error'),
        );
        break;
      default:
        if (result.error && result.error.message) {
          this.notificationService.error(this.translateService.instant('toast.error.title'), result.error.message);
        }
        break;
    }
  }

  public onForgotSuccess(result: any): void {
    this.notificationService.success(
      this.translateService.instant('auth.forgot-password.link'),
      this.translateService.instant('auth.forgot-password.confirmed.title'),
    );

    this.router.navigate(['/', AppRoutes.auth, AppRoutes.login]);
  }

  public onForgotFail(result: any): void {
    this.notificationService.error(
      this.translateService.instant('auth.forgot-password.link'),
      this.translateService.instant('auth.forgot-password.error'),
    );
  }

  public onResetSuccess(response: boolean): void {
    this.notificationService.success(
      this.translateService.instant('toast.success.title'),
      this.translateService.instant('detail.item.saved'),
    );

    this.router.navigate(['/', AppRoutes.auth, AppRoutes.login]);
  }

  public getAllShipmentCountries(): void {
    this.dxpIndexablePermissionService.loadPermissions().subscribe((permissions) => {
      let listOfCountryCodes = [];

      listOfCountryCodes = permissions.reduce((accumulator, value) => {
        const permission = value.name;

        if (
          permission.startsWith('dxp.logistics.show.') ||
          permission.startsWith('dxp.logistics.information.manage.')
        ) {
          const elements = permission.split('.');

          accumulator.push(elements[elements.length - 1]);
        }

        return accumulator;
      }, []);

      localStorage.setItem('countryCodes', JSON.stringify(listOfCountryCodes));
    });
  }

  protected prePopulateForms(): void {
    super.prePopulateForms();
    this.loginForm = this.formBuilder.group({
      email: [null],
      password: [null],
      remember: [false],
    });
  }
}
