import { AuthService } from '@capturum/auth';
import { NotificationService } from '@shared/modules/notification/notification.service';
import { Injectable } from '@angular/core';
import { ActionOptions, ActionProvider, BuilderAction } from '@capturum/builders/core';
import { TranslateService } from '@ngx-translate/core';
import { InterpolationPipe } from '@capturum/ui/api';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@environments/environment';
import { Store } from '@ngxs/store';
import { switchMap, tap } from 'rxjs/operators';
import { DocumentService } from '@core/api/document.service';
import { DocumentBatch } from '@core/models/document.model';
import { saveAs } from 'file-saver';
import { FetchActionsExecution } from '@store/general/general.actions';
import { BuilderActionType } from '@core/enums/builder-action-type.enum';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ListDownloadService implements ActionProvider {
  private presignedUrl: string;

  constructor(
    protected readonly translateService: TranslateService,
    protected readonly authService: AuthService,
    protected readonly documentService: DocumentService,
    protected readonly notificationService: NotificationService,
    protected readonly interpolationPipe: InterpolationPipe,
    protected readonly http: HttpClient,
    protected readonly store: Store,
  ) {}

  public execute(options: ActionOptions, item: any): void {
    this.showStartNotification();
    this.download(options.action, Array.isArray(item) ? item : [item]);
  }

  protected showStartNotification(): void {
    const title = this.translateService.instant('dxp.toast.info.title');
    const message = this.translateService.instant('dxp.toast.download.start');

    this.notificationService.info(title, message);
  }

  protected download(action: BuilderAction, items: any[]): void {
    let headers: HttpHeaders = new HttpHeaders();

    headers = headers.append('Authorization', 'Bearer ' + this.authService.getToken());

    let url = environment.baseUrl;

    url += this.interpolationPipe.transform(action.options.endpoint, items);

    this.http
      .post<DocumentBatch>(url, items, { headers })
      .pipe(
        tap((batchStatus) => {
          if (
            batchStatus?.batch_id &&
            (action?.key === 'print_outbound_trolley_notes' || action?.key === 'bulk_print_outbound_trolley_note')
          ) {
            this.documentService.listenOnBatch(batchStatus.batch_id, batchStatus.document);
          }
        }),
        switchMap((result: any) => {
          this.presignedUrl = result?.data?.url;

          if (result?.data?.message) {
            this.notificationService.warning(
              this.translateService.instant('dxp.toast.warning.title'),
              result.data.message,
            );
          }

          if (result?.message) {
            this.notificationService.warning(this.translateService.instant('dxp.toast.warning.title'), result.message);
          }

          if (this.presignedUrl) {
            return this.http.get(this.presignedUrl, {
              responseType: 'blob',
            });
          } else {
            return of(null);
          }
        }),
      )
      .subscribe((updatedResult) => {
        if (updatedResult) {
          const fileName: string = this.fileName(this.presignedUrl) || action.key;

          saveAs(updatedResult, fileName);
          this.store.dispatch(new FetchActionsExecution(BuilderActionType.itemDownload));
        }
      });
  }

  private fileName(url: string): string {
    const splitUrl: string[] = url?.split('?')[0]?.split('/');

    return splitUrl.length ? splitUrl[splitUrl.length - 1] : null;
  }
}
