import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { ListRendererWidgetBase } from '@capturum/builders/list-renderer';
import { UpdateChangedItem } from '@store/general/general.actions';
import { Store } from '@ngxs/store';

@Component({
  selector: 'app-reorder-lots',
  templateUrl: './reorder-lots-widget.component.html',
  styleUrls: ['./reorder-lots-widget.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReorderLotsWidgetComponent extends ListRendererWidgetBase {
  constructor(private store: Store) {
    super();
  }

  public moveUp(): void {
    this.store.dispatch(new UpdateChangedItem({ id: this.item.id, direction: -1 }, this.item));
  }

  public moveDown(): void {
    this.store.dispatch(new UpdateChangedItem({ id: this.item.id, direction: 1 }, this.item));
  }
}
