import { Injectable } from '@angular/core';
import { ResetPasswordComponent } from '../../features/admin/user/pages/reset-password/reset-password.component';
import { TranslateService } from '@ngx-translate/core';
import { first } from 'rxjs/operators';
import { NotificationService } from '@shared/modules/notification/notification.service';
import { DialogService } from 'primeng/dynamicdialog';

@Injectable({
  providedIn: 'root',
})
export class ResetPasswordDialogService {
  public isDialogOpened: boolean;

  constructor(
    private readonly dialogService: DialogService,
    private notificationService: NotificationService,
    private readonly translateService: TranslateService,
  ) {}

  public openDialog(): void {
    if (!this.isDialogOpened) {
      this.isDialogOpened = true;

      const dialogRef = this.dialogService.open(ResetPasswordComponent, {
        header: this.translateService.instant('auth.reset-password.title'),
        closable: false,
        width: '40%',
      });

      dialogRef.onClose.pipe(first()).subscribe(() => {
        this.isDialogOpened = false;
        this.notificationService.success(
          this.translateService.instant('toast.success.title'),
          this.translateService.instant('toast.success.reset'),
        );
      });
    }
  }
}
