import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ListRendererWidgetBase } from '@capturum/builders/list-renderer';
import { UntypedFormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { UpdateChangedItem } from '@store/general/general.actions';
import { Store } from '@ngxs/store';

@Component({
  selector: 'app-list-renderer-widget-input',
  template: `
    <cap-input
      [formControl]="control"
      [disabled]="field === 'quantity' && !item.auction_order_id"
      (blur)="cdr.detectChanges()">
    </cap-input>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListRendererWidgetInputComponent extends ListRendererWidgetBase implements OnInit, OnDestroy {
  public control: UntypedFormControl = new UntypedFormControl();
  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    public readonly cdr: ChangeDetectorRef,
    private readonly store: Store,
  ) {
    super();
  }

  public ngOnInit(): void {
    this.control.setValue(this.item[this.field]);

    this.control.valueChanges.pipe(debounceTime(1000), takeUntil(this.destroy$)).subscribe((value) => {
      this.store.dispatch(
        new UpdateChangedItem(this.field, {
          ...this.item,
          [this.field]: value,
        }),
      );
    });
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
