import { Injectable } from '@angular/core';
import { Observable, of, zip } from 'rxjs';
import { ApiHttpService } from '@capturum/api';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '@capturum/auth';
import { transformToLowercaseKeys } from '@core/utils/functions/transform-to-lowercase-keys';
import { CompleteConfig, IndexedDbGuard, IndexedDbService, TranslationApiModel } from '@capturum/complete';
import { catchError, filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { NavigationService } from '@core/services/navigation.service';
import { IndexableDataService } from '@core/indexDb/services/dxp-indexable-data.service';
import Dexie from 'dexie';
import { PrimeNGConfig } from 'primeng/api';
import { CacheService } from '@core/services/cache.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class DxpIndexableTranslationService extends IndexableDataService<TranslationApiModel> {
  protected endpoint = 'role/translation';
  protected outdatedEntityKey = 'translations';

  constructor(
    protected readonly api: ApiHttpService,
    protected readonly translateService: TranslateService,
    protected readonly authService: AuthService,
    protected readonly cacheService: CacheService,
    protected readonly navigationService: NavigationService,
    protected readonly completeConfig: CompleteConfig,
    protected readonly indexedDbService: IndexedDbService,
    protected readonly indexedDbGuard: IndexedDbGuard,
    protected readonly primeNGConfig: PrimeNGConfig,
    protected readonly httpClient: HttpClient,
  ) {
    super(api, translateService, authService, completeConfig, indexedDbService, indexedDbGuard);

    this.translateService.onLangChange.subscribe((response) => {
      this.setPrimengLocale(response.translations);
    });
  }

  /**
   * I would deprecate this method, but @Emendis/complete components rely on it
   */
  public loadTranslations(forceReload = false): Observable<any> {
    return this.getData(forceReload);
  }

  /**
   * Overwrite default behavior because translations are returned as an object with keys instead of an array
   */
  public resolve(): Observable<boolean> {
    return this.getData().pipe(
      map((model) => {
        return !!model && Object.keys(model).length > 0;
      }),
      takeUntil(this.destroy$),
    );
  }

  public resolvePublicTranslations(): Observable<boolean> {
    return this.hasAuthTranslations().pipe(
      filter((hasAuthTranslations: boolean) => {
        return !hasAuthTranslations;
      }),
      switchMap(() => {
        return this.httpClient.get('assets/i18n/en.json').pipe(
          tap((data) => {
            return this.translateService.setTranslation('en', data, true);
          }),
        );
      }),
      map(() => {
        return true;
      }),
    );
  }

  public onFatalError(): Observable<void> {
    return super.onFatalError().pipe(
      tap(() => {
        this.cacheService.forceLogout();
      }),
    );
  }

  public hasAuthTranslations(): Observable<boolean> {
    return this.getTable().pipe(
      switchMap((table: Dexie.Table<any, any>) => {
        return table.toArray();
      }),
      catchError(() => {
        return of([]);
      }),
      map((table) => {
        // Hardcoded, because we don't have a language yet!
        return (
          !!table &&
          table.length > 0 &&
          table[0].hasOwnProperty('value') &&
          Object.keys(table[0].value).includes('auth.sign-in.error')
        );
      }),
    );
  }

  public isMissingDxpTranslations(): Observable<boolean> {
    const language = this.getUserLanguage();

    return this.getTable().pipe(
      switchMap((table: Dexie.Table<any, any>) => {
        return table.toArray();
      }),
      map((tableData) => {
        return tableData.find((translations) => {
          return translations.id === language;
        });
      }),
      catchError(() => {
        return of([]);
      }),
      map((translationLanguage) => {
        return (
          !translationLanguage ||
          !translationLanguage.hasOwnProperty('value') ||
          !Object.keys(translationLanguage.value).includes('dxp')
        );
      }),
    );
  }

  public isDataOutdated(): Observable<boolean> {
    return zip(super.isDataOutdated(), this.isMissingDxpTranslations()).pipe(
      map(([isOutdated, isMissingTranslations]) => {
        return isOutdated || isMissingTranslations;
      }),
      catchError(() => {
        // Clear indexed data and retrieve data from URL
        return this.clearData().pipe(
          map(() => {
            return true;
          }),
        );
      }),
    );
  }

  public onTransformRequestedData(translations: any): Observable<any> {
    const lowercaseTranslations = [];

    for (const language in translations) {
      if (translations.hasOwnProperty(language)) {
        for (const translationKey in translations[language]) {
          if (translationKey === 'base-data' && typeof translations[language][translationKey] === 'object') {
            translations[language][translationKey] = transformToLowercaseKeys(translations[language][translationKey]);
          }
        }

        lowercaseTranslations.push({ id: language, value: translations[language] });
      }
    }

    return of(lowercaseTranslations);
  }

  public getUserLanguage(): string {
    const loggedInUser = this.authService.getUser();

    return loggedInUser && loggedInUser.locale
      ? loggedInUser.locale.code || this.translateService.defaultLang
      : this.translateService.defaultLang;
  }

  public onIndexedDataUpdated(data: any): Observable<any> {
    return of(data).pipe(
      tap(() => {
        return this.translateService.reloadLang(this.getUserLanguage());
      }),
    );
  }

  public onIndexedDataFinished(): Observable<any> {
    this.translateService.setDefaultLang('en');

    return this.translateService.use(this.getUserLanguage());
  }

  public setPrimengLocale(translations: Record<string, string>): void {
    this.primeNGConfig.setTranslation({
      dayNames: [
        translations['calendar-locale.day-names.sunday'],
        translations['calendar-locale.day-names.monday'],
        translations['calendar-locale.day-names.tuesday'],
        translations['calendar-locale.day-names.wednesday'],
        translations['calendar-locale.day-names.thursday'],
        translations['calendar-locale.day-names.friday'],
        translations['calendar-locale.day-names.saturday'],
      ],
      dayNamesShort: [
        translations['calendar-locale.day-names.sun'],
        translations['calendar-locale.day-names.mon'],
        translations['calendar-locale.day-names.tue'],
        translations['calendar-locale.day-names.wed'],
        translations['calendar-locale.day-names.thu'],
        translations['calendar-locale.day-names.fri'],
        translations['calendar-locale.day-names.sat'],
      ],
      dayNamesMin: [
        translations['calendar-locale.day-names.su'],
        translations['calendar-locale.day-names.mo'],
        translations['calendar-locale.day-names.tu'],
        translations['calendar-locale.day-names.we'],
        translations['calendar-locale.day-names.th'],
        translations['calendar-locale.day-names.fr'],
        translations['calendar-locale.day-names.sa'],
      ],
      monthNames: [
        translations['calendar-locale.month-names.january'],
        translations['calendar-locale.month-names.february'],
        translations['calendar-locale.month-names.march'],
        translations['calendar-locale.month-names.april'],
        translations['calendar-locale.month-names.may'],
        translations['calendar-locale.month-names.june'],
        translations['calendar-locale.month-names.july'],
        translations['calendar-locale.month-names.august'],
        translations['calendar-locale.month-names.september'],
        translations['calendar-locale.month-names.october'],
        translations['calendar-locale.month-names.november'],
        translations['calendar-locale.month-names.december'],
      ],
      monthNamesShort: [
        translations['calendar-locale.month-names.jan'],
        translations['calendar-locale.month-names.feb'],
        translations['calendar-locale.month-names.mar'],
        translations['calendar-locale.month-names.apr'],
        translations['calendar-locale.month-names.may'],
        translations['calendar-locale.month-names.jun'],
        translations['calendar-locale.month-names.jul'],
        translations['calendar-locale.month-names.aug'],
        translations['calendar-locale.month-names.sep'],
        translations['calendar-locale.month-names.oct'],
        translations['calendar-locale.month-names.nov'],
        translations['calendar-locale.month-names.dec'],
      ],
      today: translations['calendar-locale.today'],
      weekHeader: translations['calendar-locale.week-header'],
    });
  }
}
