@if (showUndo) {
  <div [class.d-none]="(visible$ | async) === false" class="action--undo" (click)="!wasRemoved ? confirm() : null">
    <div class="action--counter">
      <app-countdown (finish)="handleFinish()"></app-countdown>
    </div>
    <div class="action--message">
      @if (!wasRemoved) {
        <p>{{ 'dxp.mobile-app.saved' | translate: { entity: getEntity() } }}</p>
        <p>{{ 'dxp.mobile-app.undo.tap' | translate }}</p>
      }
      @if (wasRemoved) {
        <p class="action__canceled">{{ 'dxp.mobile-app.undo.success' | translate: { entity: getEntity() } }}</p>
      }
    </div>
  </div>
}
