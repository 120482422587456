import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { ListRendererWidgetBase } from '@capturum/builders/list-renderer';
import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { Store } from '@ngxs/store';
import { UpdateChangedItem } from '@store/general/general.actions';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-list-renderer-widget-input-number-with-buttons',
  template: `
    <cap-input-number
      decrementButtonClass="minus secondary"
      incrementButtonClass="plus secondary"
      [formControl]="control"
      [buttonLayout]="'horizontal'"
      [disabled]="field === 'quantity' && !item.auction_order_id"
      [ngClass]="{ disabled: field === 'quantity' && !item.auction_order_id }"
      [maxFractionDigits]="2"
      [min]="0"
      [showButtons]="true"
      [step]="1"
      (blur)="cdr.detectChanges()">
    </cap-input-number>
  `,
  styleUrls: ['./list-renderer-widget-input-number-with-buttons.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListRendererWidgetInputNumberWithButtonsComponent
  extends ListRendererWidgetBase
  implements OnInit, OnDestroy
{
  public control: FormControl<number> = new FormControl();
  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    public readonly cdr: ChangeDetectorRef,
    private readonly store: Store,
  ) {
    super();
  }

  public ngOnInit(): void {
    this.control.setValue(this.item[this.field]);

    this.control.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value) => {
      const newItem = {
        ...this.item,
        [this.field]: value,
      };

      this.store.dispatch(new UpdateChangedItem(this.field, newItem));
    });
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
