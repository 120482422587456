import { Injectable, Injector } from '@angular/core';

@Injectable()
export class AppInjector {
  public static setInjector(injector: Injector): void {
    AppInjector.injector = injector;
  }

  public static getInjector(): Injector {
    return AppInjector.injector;
  }

  private static injector: Injector;
}
