import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@capturum/auth';
import { IndexedDbGuard } from '@capturum/complete';
import { BasicLayoutComponent } from '@shared/modules/layouts/basic-layout/basic-layout.component';
import { MobileLayoutComponent } from '@shared/modules/layouts/mobile-layout/mobile-layout.component';
import { ForgotPasswordComponent, LoginComponent, LoginLayoutComponent, ResetPasswordComponent } from '@capturum/login';
import { DxpResolver } from '@core/resolvers/dxp.resolver';
import { NgxPermissionsGuard } from 'ngx-permissions';
import { ClearPermissionsGuard } from '@core/guards/clear-permissions.guard';
import { CheckPermissionsGuard } from '@core/guards/check-permissions.guard';
import { DxpPublicTranslationResolver } from '@core/resolvers/dxp-public-translation.resolver';
import { PublicLayoutComponent } from './shared/modules/layouts/public-layout/public-layout.component';

// @see https://github.com/AlexKhymenko/ngx-permissions/issues/148#issuecomment-701930249
// data: { permissions: {} },

const routes: Routes = [
  {
    path: '',
    redirectTo: 'admin/production',
    pathMatch: 'full',
  },
  {
    path: '',
    canActivate: [IndexedDbGuard, AuthGuard],
    canActivateChild: [AuthGuard],
    resolve: { dxp: DxpResolver },
    children: [
      {
        path: 'admin',
        component: BasicLayoutComponent,
        canActivate: [IndexedDbGuard],
        canActivateChild: [CheckPermissionsGuard, NgxPermissionsGuard],
        children: [
          // Override complete routes
          {
            path: 'builder',
            loadChildren: () => {
              return import('./features/builders/builders.module').then((m) => {
                return m.BuildersModule;
              });
            },
            data: { permissions: {} },
          },
          {
            path: 'power-bi',
            loadChildren: () => {
              return import('./features/admin/power-bi/power-bi.module').then((m) => {
                return m.PowerBiModule;
              });
            },
            data: { permissions: {} },
          },
          {
            path: 'user',
            loadChildren: () => {
              return import('./features/admin/user/user.module').then((m) => {
                return m.UserModule;
              });
            },
            data: { permissions: {} },
          },
          {
            path: 'base-data',
            loadChildren: () => {
              return import('./features/admin/base-data/base-data.module').then((m) => {
                return m.BaseDataModule;
              });
            },
            data: { permissions: {} },
          },
          {
            path: 'config',
            redirectTo: '/admin/manage',
            data: { permissions: {} },
          },
          {
            path: 'setting',
            loadChildren: () => {
              return import('./features/admin/setting/setting.module').then((m) => {
                return m.SettingModule;
              });
            },
            data: { permissions: {} },
          },
          {
            path: 'role',
            loadChildren: () => {
              return import('./features/admin/role/role.module').then((m) => {
                return m.RoleModule;
              });
            },
            data: { permissions: {} },
          },
          {
            path: 'translation',
            loadChildren: () => {
              return import('./features/admin/translation/translation.module').then((m) => {
                return m.TranslationModule;
              });
            },
            data: { permissions: {} },
          },
          // Local routes
          {
            path: '',
            redirectTo: 'production',
            pathMatch: 'full',
          },
          {
            path: 'dashboard',
            loadChildren: () => {
              return import('./features/admin/dashboard/dashboard.module').then((m) => {
                return m.DashboardModule;
              });
            },
            data: { permissions: {} },
          },
          {
            path: 'planning',
            loadChildren: () => {
              return import('./features/admin/planning/planning.module').then((m) => {
                return m.PlanningModule;
              });
            },
            data: { permissions: {} },
          },
          {
            path: 'sales',
            loadChildren: () => {
              return import('./features/admin/order/order.module').then((m) => {
                return m.OrderModule;
              });
            },
            data: { permissions: {} },
          },
          {
            path: 'product-group',
            loadChildren: () => {
              return import('./features/admin/product-group/product-group.module').then((m) => {
                return m.ProductGroupModule;
              });
            },
            data: { permissions: {} },
          },
          {
            path: 'product-grading',
            loadChildren: () => {
              return import('./features/admin/product-grading/product-grading.module').then((m) => {
                return m.ProductGradingModule;
              });
            },
            data: { permissions: {} },
          },
          {
            path: 'product',
            loadChildren: () => {
              return import('./features/admin/product/product.module').then((m) => {
                return m.ProductModule;
              });
            },
            data: { permissions: {} },
          },
          {
            path: 'production',
            loadChildren: () => {
              return import('./features/admin/production/production.module').then((m) => {
                return m.ProductionModule;
              });
            },
            data: { permissions: {} },
          },
          {
            path: 'grading',
            loadChildren: () => {
              return import('./features/admin/grading/grading.module').then((m) => {
                return m.GradingModule;
              });
            },
            data: { permissions: {} },
          },
          {
            path: 'record-graded-harvest',
            loadChildren: () => {
              return import('./features/admin/record-graded-harvest/record-graded-harvest.module').then((m) => {
                return m.RecordGradedHarvestModule;
              });
            },
            data: { permissions: {} },
          },
          {
            path: 'record-rejected-harvest',
            loadChildren: () => {
              return import('./features/admin/record-rejected-harvest/record-rejected-harvest.module').then((m) => {
                return m.RecordRejectedHarvestModule;
              });
            },
            data: { permissions: {} },
          },
          {
            path: 'block',
            loadChildren: () => {
              return import('./features/admin/block/block.module').then((m) => {
                return m.BlockModule;
              });
            },
            data: { permissions: {} },
          },
          {
            path: 'farm',
            loadChildren: () => {
              return import('./features/admin/farm/farm.module').then((m) => {
                return m.FarmModule;
              });
            },
            data: { permissions: {} },
          },
          {
            path: 'organisation',
            loadChildren: () => {
              return import('./features/admin/organisation/organisation.module').then((m) => {
                return m.OrganisationModule;
              });
            },
            data: { permissions: {} },
          },
          {
            path: 'farm-role',
            loadChildren: () => {
              return import('./features/admin/farm-role/farm-role.module').then((m) => {
                return m.FarmRoleModule;
              });
            },
            data: { permissions: {} },
          },
          {
            path: 'package',
            loadChildren: () => {
              return import('./features/admin/package/package.module').then((m) => {
                return m.PackageModule;
              });
            },
            data: { permissions: {} },
          },
          {
            path: 'upload',
            loadChildren: () => {
              return import('./features/admin/upload/upload.module').then((m) => {
                return m.UploadModule;
              });
            },
            data: { permissions: {} },
          },
          {
            path: 'grading-station',
            loadChildren: () => {
              return import('./features/admin/grading-station/grading-station.module').then((m) => {
                return m.GradingStationModule;
              });
            },
            data: { permissions: {} },
          },
          {
            path: 'graded-stock',
            loadChildren: () => {
              return import('./features/admin/graded-stock/graded-stock.module').then((m) => {
                return m.GradedStockModule;
              });
            },
            data: { permissions: {} },
          },
          {
            path: 'shipment',
            loadChildren: () => {
              return import('./features/admin/shipment/shipment.module').then((m) => {
                return m.ShipmentModule;
              });
            },
            data: { permissions: {} },
          },
          {
            path: 'observation',
            loadChildren: () => {
              return import('./features/admin/observation/observation.module').then((m) => {
                return m.ObservationModule;
              });
            },
            data: { permissions: {} },
          },
          {
            path: 'more-actions',
            loadChildren: () => {
              return import('./features/admin/more-actions/more-actions.module').then((m) => {
                return m.MoreActionsModule;
              });
            },
            data: { permissions: {} },
          },
          {
            path: 'manage',
            loadChildren: () => {
              return import('./features/admin/manage/manage.module').then((m) => {
                return m.ManageModule;
              });
            },
            data: { permissions: {} },
          },
          {
            path: 'delivery-condition',
            loadChildren: () => {
              return import('./features/admin/delivery-condition/delivery-condition.module').then((m) => {
                return m.DeliveryConditionModule;
              });
            },
            data: { permissions: {} },
          },
          {
            path: 'product-packing',
            loadChildren: () => {
              return import('./features/admin/product-packing/product-packing.module').then((m) => {
                return m.ProductPackingModule;
              });
            },
            data: { permissions: {} },
          },
          {
            path: 'product-base-rate',
            loadChildren: () => {
              return import('./features/admin/product-base-rate/product-base-rate.module').then((m) => {
                return m.ProductBaseRateModule;
              });
            },
            data: { permissions: {} },
          },
          {
            path: 'sales-channel',
            loadChildren: () => {
              return import('./features/admin/sales-channel/sales-channel.module').then((m) => {
                return m.SalesChannelModule;
              });
            },
            data: { permissions: {} },
          },
          {
            path: 'sales-contract',
            loadChildren: () => {
              return import('./features/admin/sales-contract/sales-contract.module').then((m) => {
                return m.SalesContractModule;
              });
            },
            data: { permissions: {} },
          },
          {
            path: 'region',
            loadChildren: () => {
              return import('./features/admin/region/region.module').then((m) => {
                return m.RegionModule;
              });
            },
            data: { permissions: {} },
          },
          {
            path: 'delivery-lag',
            loadChildren: () => {
              return import('./features/admin/delivery-lag/delivery-lag.module').then((m) => {
                return m.DeliveryLagModule;
              });
            },
            data: { permissions: {} },
          },
          {
            path: 'forecast-allocation',
            loadChildren: () => {
              return import('./features/admin/forecast-allocation/forecast-allocation.module').then((m) => {
                return m.ForecastAllocationModule;
              });
            },
            data: { permissions: {} },
          },
          {
            path: 'insight',
            loadChildren: () => {
              return import('./features/admin/insight/insight.module').then((m) => {
                return m.InsightModule;
              });
            },
            data: { permissions: {} },
          },
          {
            path: 'batch',
            loadChildren: () => {
              return import('./features/admin/batch/batch.module').then((m) => {
                return m.BatchModule;
              });
            },
            data: { permissions: {} },
          },
          {
            path: 'price-rate',
            loadChildren: () => {
              return import('./features/admin/price-rate/price-rate.module').then((m) => {
                return m.PriceRateModule;
              });
            },
            data: { permissions: {} },
          },
          {
            path: 'delivery-order',
            loadChildren: () => {
              return import('./features/admin/delivery-order/delivery-order.module').then((m) => {
                return m.DeliveryOrderModule;
              });
            },
            data: { permissions: {} },
          },
          {
            path: 'location',
            loadChildren: () => {
              return import('./features/admin/location/location.module').then((m) => {
                return m.LocationModule;
              });
            },
            data: { permissions: {} },
          },
          {
            path: 'journals',
            loadChildren: () => {
              return import('./features/admin/journals/journals.module').then((m) => {
                return m.JournalsModule;
              });
            },
            data: { permissions: {} },
          },
          {
            path: 'floriday-errors',
            loadChildren: () => {
              return import('./features/admin/floriday-errors/floriday-errors.module').then((m) => {
                return m.FloridayErrorsModule;
              });
            },
            data: { permissions: {} },
          },
          {
            path: 'transport',
            loadChildren: () => {
              return import('./features/admin/transport/transport.module').then((m) => {
                return m.TransportModule;
              });
            },
            data: { permissions: {} },
          },
          {
            path: 'harvest-delivery-note',
            loadChildren: () => {
              return import('./features/admin/harvest-delivery-note/harvest-delivery-note.module').then((m) => {
                return m.HarvestDeliveryNoteModule;
              });
            },
            data: { permissions: {} },
          },
          {
            path: 'auction-sales',
            loadChildren: () => {
              return import('./features/admin/auction-sales/auction-sales.module').then((m) => {
                return m.AuctionSalesModule;
              });
            },
            data: { permissions: {} },
          },
          {
            path: 'auction-strategy',
            loadChildren: () => {
              return import('./features/admin/auction-strategy/auction-strategy.module').then((m) => {
                return m.AuctionStrategyModule;
              });
            },
            data: { permissions: {} },
          },
          {
            path: 'holiday',
            loadChildren: () => {
              return import('./features/admin/holidays/holidays.module').then((m) => {
                return m.HolidaysModule;
              });
            },
            data: { permissions: {} },
          },
          {
            path: 'village-planning',
            loadChildren: () => {
              return import('./features/admin/village-planning/village-planning.module').then((m) => {
                return m.VillagePlanningModule;
              });
            },
            data: { permissions: {} },
          },
          {
            path: 'trade-item',
            loadChildren: () => {
              return import('./features/admin/trade-item/trade-item.module').then((m) => {
                return m.TradeItemModule;
              });
            },
            data: { permissions: {} },
          },
          {
            path: 'product-file',
            loadChildren: () => {
              return import('./features/admin/product-file/product-file.module').then((m) => {
                return m.ProductFileModule;
              });
            },
            data: { permissions: {} },
          },
          {
            path: 'boxed-stock',
            loadChildren: () => {
              return import('./features/admin/boxed-stock/boxed-stock.module').then((m) => {
                return m.BoxedStockModule;
              });
            },
            data: { permissions: {} },
          },
          {
            path: 'allocation',
            loadChildren: () => {
              return import('./features/admin/allocation/allocation.module').then((m) => {
                return m.AllocationModule;
              });
            },
            data: { permissions: {} },
          },
          {
            path: 'package-management',
            loadChildren: () => {
              return import('./features/admin/package-management/package-management.module').then((m) => {
                return m.PackageManagementModule;
              });
            },
            data: { permissions: {} },
          },
          {
            path: 'fulfillment',
            loadChildren: () => {
              return import('./features/admin/fulfillment/fulfillment.module').then((m) => {
                return m.FulfillmentModule;
              });
            },
            data: { permissions: {} },
          },
          {
            path: 'rejected-harvest',
            loadChildren: () => {
              return import('./features/admin/rejected-harvest/rejected-harvest.module').then((m) => {
                return m.RejectedHarvestModule;
              });
            },
            data: { permissions: {} },
          },
          {
            path: 'logistics',
            loadChildren: () => {
              return import('./features/admin/logistic/logistic.module').then((m) => {
                return m.LogisticModule;
              });
            },
            data: { permissions: {} },
          },
          {
            path: 'batch-planning',
            loadChildren: () => {
              return import('./features/admin/batch-planning/batch-planning.module').then((m) => {
                return m.BatchPlanningModule;
              });
            },
            data: { permissions: {} },
          },
          {
            path: 'finance',
            loadChildren: () => {
              return import('./features/admin/finance/finance.module').then((m) => {
                return m.FinanceModule;
              });
            },
            data: { permissions: {} },
          },
        ],
      },
      {
        path: 'mobile',
        component: MobileLayoutComponent,
        canActivate: [IndexedDbGuard],
        canActivateChild: [CheckPermissionsGuard, NgxPermissionsGuard],
        data: {
          permissions: {
            only: ['dxp.mobile.app.access'],
            redirectTo: '/',
          },
        },
        children: [
          {
            path: '',
            redirectTo: 'dashboard',
            pathMatch: 'full',
          },
          {
            path: 'dashboard',
            loadChildren: () => {
              return import('./features/mobile/mobile-dashboard/mobile-dashboard.module').then((m) => {
                return m.MobileDashboardModule;
              });
            },
            data: { permissions: {} },
          },
          {
            path: 'harvesting',
            loadChildren: () => {
              return import(
                './features/mobile/harvesting-flow/mobile-harvest-collection-dashboard/mobile-harvest-collection-dashboard.module'
              ).then((m) => {
                return m.MobileHarvestCollectionDashboardModule;
              });
            },
            data: { permissions: {} },
          },
          {
            path: 'growing',
            loadChildren: () => {
              return import(
                './features/mobile/growing-flow/mobile-growing-dashboard/mobile-growing-dashboard.module'
              ).then((m) => {
                return m.MobileGrowingDashboardModule;
              });
            },
            data: { permissions: {} },
          },
          {
            path: 'shipment',
            loadChildren: () => {
              return import(
                './features/mobile/shipment-flow/mobile-shipment-dashboard/mobile-shipment-dashboard.module'
              ).then((m) => {
                return m.MobileShipmentDashboardModule;
              });
            },
            data: { permissions: {} },
          },
          {
            path: 'start-shipment',
            loadChildren: () => {
              return import('./features/mobile/shipment-flow/mobile-start-shipment/mobile-start-shipment.module').then(
                (m) => {
                  return m.MobileStartShipmentModule;
                },
              );
            },
            data: { permissions: {} },
          },
          {
            path: 'record-empty',
            loadChildren: () => {
              return import('./features/mobile/mobile-record-empty/mobile-record-empty.module').then((m) => {
                return m.MobileRecordEmptyModule;
              });
            },
            data: { permissions: {} },
          },
          {
            path: 'confirm-shipment',
            loadChildren: () => {
              return import(
                './features/mobile/shipment-flow/mobile-confirm-shipment/mobile-confirm-shipment.module'
              ).then((m) => {
                return m.MobileConfirmShipmentModule;
              });
            },
            data: { permissions: {} },
          },
          {
            path: 'grading',
            loadChildren: () => {
              return import(
                './features/mobile/grading-flow/mobile-grading-dashboard/mobile-grading-dashboard.module'
              ).then((m) => {
                return m.MobileGradingDashboardModule;
              });
            },
            data: { permissions: {} },
          },
          {
            path: 'observation',
            loadChildren: () => {
              return import('./features/mobile/growing-flow/mobile-observation/mobile-observation.module').then((m) => {
                return m.MobileObservationModule;
              });
            },
            data: { permissions: {} },
          },
          {
            path: 'stock',
            loadChildren: () => {
              return import('./features/mobile/grading-flow/mobile-stock/mobile-stock.module').then((m) => {
                return m.MobileStockModule;
              });
            },
            data: { permissions: {} },
          },
          {
            path: 'rejected-stock',
            loadChildren: () => {
              return import('./features/mobile/grading-flow/mobile-rejected-stock/mobile-rejected-stock.module').then(
                (m) => {
                  return m.MobileRejectedStockModule;
                },
              );
            },
            data: { permissions: {} },
          },
          {
            path: 'sorting',
            loadChildren: () => {
              return import('./features/mobile/grading-flow/mobile-sorting/mobile-sorting.module').then((m) => {
                return m.MobileSortingModule;
              });
            },
            data: { permissions: {} },
          },
          {
            path: 'grading-session',
            loadChildren: () => {
              return import('./features/mobile/grading-flow/mobile-grading-session/mobile-grading-session.module').then(
                (m) => {
                  return m.MobileGradingSessionModule;
                },
              );
            },
            data: { permissions: {} },
          },
          {
            path: 'harvest-move',
            loadChildren: () => {
              return import('./features/mobile/grading-flow/mobile-harvest-move/mobile-harvest-move.module').then(
                (m) => {
                  return m.MobileHarvestMoveModule;
                },
              );
            },
            data: { permissions: {} },
          },
          {
            path: 'sync-errors',
            loadChildren: () => {
              return import('./features/mobile/sync-errors/sync-errors.module').then((m) => {
                return m.SyncErrorsModule;
              });
            },
            data: { permissions: {} },
          },
          {
            path: 'harvest-delivery-note',
            loadChildren: () => {
              return import('./features/mobile/harvesting-flow/mobile-delivery-note/mobile-delivery-note.module').then(
                (m) => {
                  return m.MobileDeliveryNoteModule;
                },
              );
            },
            data: { permissions: {} },
          },
          {
            path: 'fulfillment',
            loadChildren: () => {
              return import(
                './features/mobile/mobile-fulfillment/mobile-fulfillment-dashboard/mobile-fulfillment.module'
              ).then((m) => {
                return m.MobileFulfillmentModule;
              });
            },
            data: { permissions: {} },
          },
          {
            path: 'record-rejections',
            loadChildren: () => {
              return import(
                './features/mobile/mobile-fulfillment/mobile-reject-received-shipment/mobile-reject-received-shipment.module'
              ).then((m) => {
                return m.MobileRejectReceivedShipmentModule;
              });
            },
            data: { permissions: {} },
          },
          {
            path: 'temperature',
            loadChildren: () => {
              return import('./features/mobile/record-temperature/record-temperature.module').then((m) => {
                return m.MobileRecordTemperatureModule;
              });
            },
            data: { permissions: {} },
          },
          {
            path: 'reports',
            loadChildren: () => {
              return import('./features/admin/reports/reports.module').then((m) => {
                return m.ReportsModule;
              });
            },
            data: { permissions: {} },
          },
          {
            path: 'confirm-lots',
            loadChildren: () => {
              return import('./features/mobile/mobile-fulfillment/mobile-confirm-lots/mobile-confirm-lots.module').then(
                (m) => {
                  return m.MobileConfirmLotsModule;
                },
              );
            },
            data: { permissions: {} },
          },
        ],
      },
    ],
  },
  {
    path: 'public',
    component: PublicLayoutComponent,
    resolve: { publicTranslations: DxpPublicTranslationResolver },
    data: { permissions: {} },
    children: [
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full',
      },
      {
        path: 'dashboard',
        loadChildren: () => {
          return import('./features/public/public-dashboard/public-dashboard.module').then((m) => {
            return m.PublicDashboardModule;
          });
        },
        data: { permissions: {} },
      },
      {
        path: 'shipment',
        loadChildren: () => {
          return import('./features/public/receive-shipment/receive-shipment.module').then((m) => {
            return m.ReceiveShipmentModule;
          });
        },
        data: { permissions: {} },
      },
      {
        path: 'temperature',
        loadChildren: () => {
          return import('./features/public/record-temperature/record-temperature.module').then((m) => {
            return m.RecordTemperatureModule;
          });
        },
        data: { permissions: {} },
      },
    ],
  },
  {
    path: 'auth',
    component: LoginLayoutComponent,
    canActivate: [IndexedDbGuard],
    resolve: { publicTranslations: DxpPublicTranslationResolver },
    canActivateChild: [ClearPermissionsGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: '/auth/login',
      },
      {
        path: 'login',
        component: LoginComponent,
        data: { permissions: {} },
      },
      {
        path: 'forgot-password',
        component: ForgotPasswordComponent,
        data: { permissions: {} },
      },
      {
        path: 'reset-password/:token/:email',
        component: ResetPasswordComponent,
        data: { permissions: {} },
      },
      {
        path: 'reset-password/:token',
        component: ResetPasswordComponent,
        data: { permissions: {} },
      },
      {
        path: 'activate/:token/:email',
        component: ResetPasswordComponent,
        data: { permissions: {} },
      },
    ],
  },
  {
    path: '**',
    redirectTo: 'auth/login',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'top',
      enableTracing: false,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
