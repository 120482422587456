<router-outlet></router-outlet>
<app-notification></app-notification>
@if (!(existsSyncErrors$ | async | boolify)) {
  <app-undo-action></app-undo-action>
}

<p-confirmDialog #cd appendTo="body">
  <p-footer>
    <cap-button
      styleClass="secondary"
      type="button"
      [label]="(confirmation?.rejectLabel | translate) || ('confirm.no' | translate)"
      (onClick)="cd.reject()">
    </cap-button>

    <cap-button
      styleClass="primary"
      data-test="dialog-confirm-button"
      type="button"
      [label]="(confirmation?.acceptLabel | translate) || ('confirm.yes' | translate)"
      (onClick)="cd.accept()">
    </cap-button>
  </p-footer>
</p-confirmDialog>
