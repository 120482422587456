import { NotificationService } from '@shared/modules/notification/notification.service';
import { Injectable } from '@angular/core';
import { ActionOptions, ActionProvider, BuilderAction } from '@capturum/builders/core';
import { ConfirmationService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { InterpolationPipe } from '@capturum/ui/api';
import { EntityAction } from '@core/enums/entity-action.enum';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { Store } from '@ngxs/store';
import { FetchActionsExecution } from '@store/general/general.actions';
import { BuilderActionType } from '@core/enums/builder-action-type.enum';

@Injectable({
  providedIn: 'root',
})
export class ListDeleteService implements ActionProvider {
  constructor(
    private readonly confirmationService: ConfirmationService,
    private readonly translateService: TranslateService,
    private readonly notificationService: NotificationService,
    private readonly interpolationPipe: InterpolationPipe,
    private readonly http: HttpClient,
    private readonly store: Store,
  ) {}

  public execute(options: ActionOptions, item: any): void {
    this.confirmationService.confirm({
      header: this.translateService.instant('confirm.title'),
      message: this.translateService.instant('list.delete_confirmation'),
      accept: () => {
        if (options?.action?.options?.endpoint?.method) {
          this.deleteItem(options.action, item);
        }
      },
    });
  }

  private deleteItem(action: BuilderAction, item: any): void {
    let url = environment.baseUrl;

    url += this.interpolationPipe.transform(action.options.endpoint.url, item);

    this.http[action.options?.endpoint?.method?.toLocaleLowerCase()](url).subscribe(() => {
      this.notificationService.entityActionSuccess('item', EntityAction.delete);
      this.store.dispatch(new FetchActionsExecution(BuilderActionType.itemDelete));
    });
  }
}
