import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { combineLatest, Observable, of, zip } from 'rxjs';
import { Injectable } from '@angular/core';
import { catchError, finalize, first, map, take, tap } from 'rxjs/operators';
import * as Sentry from '@sentry/angular-ivy';
import { DateHelper } from '@core/utils/date.helper';
import { DatesFormats } from '@core/configs/date-config';
import { DxpIndexableModuleResolver } from '@core/indexDb/resolvers/dxp-indexable-module.resolver';
import { DxpIndexableSettingResolver } from '@core/indexDb/resolvers/dxp-indexable-setting.resolver';
import { NotificationService } from '@shared/modules/notification/notification.service';
import { LoadingService } from '@capturum/ui/loader';
import { DxpIndexableBaseDataKeyResolver } from '@core/indexDb/resolvers/dxp-indexable-base-data-key.resolver';
import { DxpIndexablePrivateTranslationResolver } from '@core/indexDb/resolvers/dxp-indexable-private-translation.resolver';
import { DxpIndexableBaseDataValueResolver } from '@core/indexDb/resolvers/dxp-indexable-base-data-value.resolver';
import { DxpIndexableBaseDataResolver } from '@core/indexDb/resolvers/dxp-indexable-base-data.resolver';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '@environments/environment';
import { AuthService } from '@capturum/auth';
import { CacheService } from '@core/services/cache.service';

@Injectable({ providedIn: 'root' })
export class DxpResolver {
  public constructor(
    private dxpBaseDataResolver: DxpIndexableBaseDataResolver,
    private dxpBaseDataKeyResolver: DxpIndexableBaseDataKeyResolver,
    private dxpBaseDataValueResolver: DxpIndexableBaseDataValueResolver,
    private dxpPrivateTranslationResolver: DxpIndexablePrivateTranslationResolver,
    private dxpSettingResolver: DxpIndexableSettingResolver,
    private dxpModuleResolver: DxpIndexableModuleResolver,
    private notificationService: NotificationService,
    private authService: AuthService,
    private loadingService: LoadingService,
    private translateService: TranslateService,
    private cacheService: CacheService,
  ) {}

  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const user = this.authService.getUser();

    if (user) {
      Sentry.setUser({
        id: user.id.toString(),
        username: user.name,
        email: user.email,
      });
    }

    Sentry.setTag('version', environment.gitVersion);

    this.translateService.getTranslation('en').pipe(first()).subscribe();

    return combineLatest([
      this.dxpBaseDataResolver.resolve(route, state),
      this.dxpBaseDataKeyResolver.resolve(route, state),
      this.dxpBaseDataValueResolver.resolve(route, state),
      this.dxpSettingResolver.resolve(route, state),
      this.dxpModuleResolver.resolve(route, state),
      this.dxpPrivateTranslationResolver.resolve(route, state),
    ]).pipe(
      map((resolvers: boolean[]) => {
        // Update when last data was fetched so we can send this to the BE next time we login to see if any data has
        // changed since the last time we filled our indexDB
        localStorage.setItem('last_data_fetched_at', DateHelper.getCurrentTimestamp(DatesFormats.sendFormat));

        return !resolvers.includes(false);
      }),
      catchError((err) => {
        zip([
          this.translateService.stream('toast.error.title'),
          this.translateService.stream('toast.error.message'),
        ]).pipe(
          tap(([title, message]) => {
            this.notificationService.error(title, message);

            console.error(err);
          }),
        );

        this.cacheService.clearCache(false);

        return of(false);
      }),
      finalize(() => {
        this.loadingService.hideLoader();
      }),
      take(1),
    );
  }
}
