import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ActivationEnd, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { HttpCancelService } from '@core/services/http-cancel.service';

@Injectable()
export class ManageHttpInterceptor implements HttpInterceptor {
  constructor(
    private readonly router: Router,
    private readonly httpCancelService: HttpCancelService,
  ) {
    router.events.subscribe((event) => {
      // An event triggered at the end of the activation part of the Resolve phase of routing.
      if (event instanceof ActivationEnd) {
        // Cancel pending calls
        this.httpCancelService.cancelPendingRequests();
      }
    });
  }

  public intercept<T>(req: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<T>> {
    // Prevent cancelling all pending requests that are of type DELETE, POST, PUT, etc.

    return req.method !== 'GET'
      ? next.handle(req)
      : next.handle(req).pipe(takeUntil(this.httpCancelService.onCancelPendingRequests()));
  }
}
