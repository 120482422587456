import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ListRendererWidgetBase } from '@capturum/builders/list-renderer';
import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UpdateChangedItem } from '@store/general/general.actions';
import { Store } from '@ngxs/store';

@Component({
  selector: 'app-list-renderer-widget-checkbox',
  template: `<cap-checkbox [binary]="binary" [formControl]="control"></cap-checkbox>`,
  encapsulation: ViewEncapsulation.None,
})
export class ListRendererWidgetCheckboxComponent extends ListRendererWidgetBase implements OnInit, OnDestroy {
  public binary = true;
  public control: FormControl = new FormControl();
  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private readonly store: Store) {
    super();
  }

  public ngOnInit(): void {
    this.control.setValue(!!this.item[this.field]);
    this.control.valueChanges.pipe(takeUntil(this.destroy$))?.subscribe((value) => {
      this.store.dispatch(new UpdateChangedItem(value, this.item));
    });
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
