import { Pipe, PipeTransform } from '@angular/core';
import { NotificationMessage } from '../base/notification.model';

@Pipe({
  name: 'removable',
})
export class RemovablePipe implements PipeTransform {
  public transform(notifications: NotificationMessage[], args?: any): boolean {
    return notifications.every((notification) => {
      return !!notification.removed;
    });
  }
}
