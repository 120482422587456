import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { map, tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class DxpPublicTranslationResolver {
  public constructor(
    private readonly httpClient: HttpClient,
    private readonly translateService: TranslateService,
  ) {}

  public setPublicTranslations(): Observable<any> {
    return this.httpClient.get('assets/i18n/en.json').pipe(
      tap((data) => {
        this.translateService.setTranslation('en', data, true);
      }),
    );
  }

  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.setPublicTranslations().pipe(
      tap(() => {
        return this.translateService.use('en');
      }),
      map(Boolean),
    );
  }
}
