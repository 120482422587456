import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { NotificationService } from '@shared/modules/notification/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { ResetPasswordDialogService } from '@core/services/reset-password-dialog.service';
import { AuthService } from '@capturum/auth';

@Injectable({
  providedIn: 'root',
})
export class ErrorNotifyInterceptor implements HttpInterceptor {
  constructor(
    private notificationService: NotificationService,
    private translateService: TranslateService,
    private authService: AuthService,
    private passwordResetDialogService: ResetPasswordDialogService,
  ) {}

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {
        this.handleError(err, request);
        this.catchErrorLogic(err, request);

        return throwError(err);
      }),
    );
  }

  private defaultParsingErrors(err: HttpErrorResponse): void {
    if (this.exceptionUrls(err)) {
      if (err?.error?.errors && typeof err.error.errors === 'object') {
        const errorKeys = Object.keys(err.error.errors);

        if (errorKeys && errorKeys.length) {
          const firstError = err.error.errors[errorKeys[0]][0];

          this.notificationService.error(this.translateService.instant('toast.error.title'), firstError);
        }
      } else if (err.error && err.error.message) {
        this.notificationService.error(this.translateService.instant('toast.error.title'), err.error.message);
      }
    }
  }

  private exceptionUrls(err: HttpErrorResponse): boolean {
    let response = false;

    // @TODO: find better solution for this
    response =
      (err.status === 422 || err.status === 500) &&
      (err.url.endsWith('/auth/login') || err.url.endsWith('/auth/recovery'));

    return !response;
  }

  private notAuthorizeHandle(err: HttpErrorResponse): void {
    this.authService.logout();
  }

  private handleError(err: HttpErrorResponse, request?: HttpRequest<any>): void {
    if (
      err instanceof HttpErrorResponse &&
      err.error instanceof Blob &&
      err.headers.get('Content-Type') === 'application/json'
    ) {
      const reader = new FileReader();

      reader.addEventListener('loadend', (e) => {
        const errorFromBlob = {
          error: JSON.parse(e.srcElement['result']),
        } as any; /* tslint:disable-line deprecation */

        this.catchErrorLogic(errorFromBlob, request);
      });
      reader.readAsText(err.error);
    }
  }

  private catchErrorLogic(err: HttpErrorResponse, request?: HttpRequest<any>): void {
    switch (err.status) {
      case 401:
      case 403:
        this.notAuthorizeHandle(err);
        break;
      default:
        this.defaultParsingErrors(err);
    }
  }
}
