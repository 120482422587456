import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { timer } from 'rxjs';
import { map, take } from 'rxjs/operators';

@Component({
  selector: 'app-countdown',
  templateUrl: './countdown.component.html',
  styleUrls: ['./countdown.component.scss'],
})
export class CountdownComponent implements OnInit {
  @Input() public startTimer = 30;
  @Output() public finish: EventEmitter<void> = new EventEmitter();
  public remainingTime: number;

  public ngOnInit(): void {
    this.countDown();
  }

  public countDown(): void {
    const start = this.startTimer;

    timer(100, 1000)
      .pipe(
        map((i) => {
          return start - i;
        }),
        take(start + 1),
      )
      .subscribe((i) => {
        this.remainingTime = i;

        if (!i) {
          this.finish.emit();
        }
      });
  }
}
