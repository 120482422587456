import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { DxpIndexableModuleService } from '@core/indexDb/services/dxp-indexable-module.service';

@Injectable({ providedIn: 'root' })
export class DxpIndexableModuleResolver {
  constructor(private indexableService: DxpIndexableModuleService) {}

  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.indexableService.resolve();
  }
}
