<div class="content">
  <div class="content-body">
    <app-alert
      [message]="'dxp.reset-password-alert-message' | translate"
      [title]="'auth.reset-password.title' | translate"></app-alert>

    <form [formGroup]="form" (ngSubmit)="submit()">
      <div class="form-group">
        <cap-input
          [placeholder]="'user.detail.password' | translate"
          type="password"
          [label]="'user.detail.password' | translate"
          formControlName="password">
        </cap-input>
      </div>

      <div class="form-group">
        <cap-input
          [placeholder]="'user.detail.password-confirmation' | translate"
          type="password"
          [label]="'user.detail.password-confirmation' | translate"
          formControlName="password_confirmation">
        </cap-input>
      </div>
    </form>
  </div>
</div>
