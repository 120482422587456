export function transformToLowercaseKeys<T>(origObj: T): T {
  return Object.keys(origObj).reduce((newObj, key) => {
    const currentValue = origObj[key];

    newObj[key.toLowerCase()] =
      typeof currentValue === 'object' ? transformToLowercaseKeys(currentValue) : currentValue;

    return newObj;
  }, {} as T);
}
